console.log('Conexion con parcel');
import * as THREE from './js/build/three.module.js';
import { OrbitControls } from './js/examples/jsm/controls/OrbitControls.js';
import { PointerLockControls } from './js/examples/jsm/controls/PointerLockControls.js';
import { GLTFLoader } from './js/examples/jsm/loaders/GLTFLoader.js';
import { RGBELoader } from './js/examples/jsm/loaders/RGBELoader.js';
import { FlakesTexture } from './FlakesTexture.js';
import { ParametricGeometry } from './js/examples/jsm/geometries/ParametricGeometry.js';
import { IcosahedronBufferGeometry } from './js/src/geometries/IcosahedronGeometry.js';
import { BufferGeometry } from './js/src/core/BufferGeometry.js'
import { setQuaternionFromProperEuler } from 'three/src/math/MathUtils.js';
import { TextureLoader } from './js/src/loaders/TextureLoader.js';
import { OBJLoader } from './js/examples/jsm/loaders/OBJLoader.js';

import  vShader  from './shaders/vertexShader.glsl.js';
import  fShader  from './shaders/fragmentShader.glsl.js';

//Importar Frag
import frag from './shaders/color.frag';
import { GeometryUtils } from 'three';

//import { DoubleSide } from 'three';


let scene, camera, light, directionLight, renderer;

function init(){ 
    scene = new THREE.Scene();
    scene.background = new THREE.Color(0x000000);
    //scene.add(new THREE.GridHelper(10000, 100, 1));
    scene.fog = new THREE.Fog(0x000000, 0, 5000);

    camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 45, 30000 );
    camera.position.set(0, 150, 600);

    light = new THREE.AmbientLight(0x404040);
    scene.add(light);

    directionLight = new THREE.DirectionalLight(0xffeedd);
    directionLight.position.set(2,3,1);
    scene.add(directionLight);

    renderer = new THREE.WebGLRenderer({
        antialias:true,
        alpha:true
    });
    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.setPixelRatio(window.devicePixelRatio)
    document.body.appendChild( renderer.domElement );
    renderer.render( scene, camera );

    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure= 1.25;
    renderer.outputEncoding = THREE.sRGBEncoding;

     };


init();
render();

let xdir = 0, zdir = 0
let tiempoI, tiempoF, vel, delta

//MOverse
const pControl = new PointerLockControls(camera, renderer.domElement)

document.getElementById('btnPlay').onclick = ()=>{
    pControl.lock()
}

document.addEventListener('keydown', (e)=>{
    switch (e.keyCode) {
        case 37:
            xdir = -1
            break;
        case 38:
            zdir = 1
            break;
        case 39:
            xdir = 1
            break;
        case 40:
            zdir = -1
            break;
    }
})

document.addEventListener('keyup', (e)=>{
    switch (e.keyCode) {
        case 37:
            xdir = 0
            break;
        case 38:
            zdir = 0
            break;
        case 39:
            xdir = 0
            break;
        case 40:
            zdir = 0
            break;
    }
})

tiempoI = Date.now()
vel = 250

animate();

function animate() {

  requestAnimationFrame( animate );

  if(pControl.isLocked === true){
      tiempoF = Date.now()

      delta = (tiempoF - tiempoI)/1000

      let xDis = xdir * vel * delta
      let zDis = zdir * vel * delta

      pControl.moveRight(xDis)
      pControl.moveForward(zDis)

      tiempoI = tiempoF
     
  }
  
 render();


   
}






// //Responsive
window.addEventListener('resize', redimensionar);
 function redimensionar(){
     camera.aspect = window.innerWidth / window.innerHeight;
     camera.updateProjectionMatrix()
     renderer.setSize(window.innerWidth, window.innerHeight);
     
 }




    
  
 let texture = new THREE.CanvasTexture(new FlakesTexture());
 texture.wrapS = THREE.RepeatWrapping;
 texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.x =10;
  texture.repeat.y = 6;

const ballMaterial= {
    clearcoat: 1.0,
    cleacoatRoughness:0.1,
    metalness: 0.9,
    roughness:0.5,
    color: 0xff00ff,
    normalMap: texture,
    normalScale: new THREE.Vector2(0.15,0.15),
    
};
 


function particles(){ 

//esfera dos Correcto es la esfera verde 
 const esferaGeo1= new THREE.SphereGeometry(50,30, 30);
 const esferaMaterial1 = new THREE.MeshPhysicalMaterial({
    transparent:true,
    depthTest: true,
    
    visible: true,
    color: 0x02d4bc,
    roughness:0.83,
    metalness:0.607,
    reflectivity:0.226000,
    clearcoat:0.36,
    fog: true,
    wireframe:true
 });
 const esfera1 = new THREE.Mesh(esferaGeo1,esferaMaterial1);
 esfera1.position.set(150, 90,-90)
scene.add(esfera1);
}
particles();
    


const esferaGeo1= new THREE.SphereGeometry(50,30, 30);
const esferaMaterial = new THREE.MeshNormalMaterial({
  transparent: false,
  opacity: 0.46,
  side: THREE.DoubleSide
});
 const esfera1 = new THREE.Mesh(esferaGeo1,esferaMaterial);
 esfera1.position.set(-150, 90,-900)
scene.add(esfera1);




// plano GIrado a 90 grados 
 const planeGeo = new THREE.PlaneGeometry(5000,5000);
 const PlaneMaterial = new THREE.MeshPhysicalMaterial(ballMaterial)
 const plane = new THREE.Mesh(planeGeo,PlaneMaterial);
 scene.add (plane);
 plane.rotation.x = - Math.PI / 2;



//Controles
  const control = new OrbitControls(camera, renderer.domElement);

const clock=new THREE.Clock()
  const tick = ()=>{
    renderer.render(scene,camera)
    window.requestAnimationFrame(tick)
    uniforms.time.value=clock.getElapsedTime()
  }
  







const vertices = [];
for(let i= 0; i<9000;i++){

  const x = THREE.MathUtils.randFloatSpread(10000);
  const y = THREE.MathUtils.randFloatSpread(10000);
  const z = THREE.MathUtils.randFloatSpread(10000);

  vertices.push(x, y, z);
}




const puntoGeo = new THREE.BufferGeometry();
puntoGeo.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3) );
const puntoMaterial = new THREE.PointsMaterial({
  color:0xC717FC, 
  size:5
  
});
const punto = new THREE.Points(puntoGeo,puntoMaterial);
scene.add(punto);

 

const torusGeo = new THREE.TorusGeometry(10000, 5000, 1000, 200);
const torusMaterial = new THREE.PointsMaterial( { 
  color: 0xffff00, 
  side: THREE.DoubleSide, 
  
  blending: THREE.AdditiveBlending,
  
} );
const torus = new THREE.Points(torusGeo, torusMaterial);
//scene.add(torus)
torus.rotation.x = - Math.PI / 2;
torus.position.y= 70;





const animate1 = function(){
  requestAnimationFrame(animate1);
torus.rotation.z -= 0.001;
punto.rotation.y += 0.0005; 
};
scene.traverse(function(PointsMaterial){
  if(PointsMaterial.Points ===true){
      torusMaterial.rotation.y += 0.01;
      punto.rotation.y += 0.01;   
  }
});



function render() {

  renderer.render( scene, camera );

}



animate1();

